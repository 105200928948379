<template>
  <div class="container layout-container">
    <div class="layout-bar">
      <el-button type="primary" @click="showUpload">新增</el-button>
      <el-button type="danger" @click="offlineBatch">下架</el-button>
      <el-button type="primary" @click="onlineBatch">上架</el-button>
      <el-button type="primary" @click="reload">刷新</el-button>
    </div>
    <div class="layout-content" ref="container">
      <el-table
        :data="list"
        :height="height"
        row-key="id"
        border style="width: 100%"
        v-loading="loading"
        ref="table"
        @selection-change="whenSelectChanged"
      >
        <el-table-column
          type="selection" width="40" align="center" :reserve-selection="true" fixed="left"
        />
        <el-table-column prop="id" label="id" width="80" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="70" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" inputType="options" :options="statusOptions" :column="scope.column" @filter="filter" />
          </template>
          <template v-slot:default="scope">
            <div :class="`status-${scope.row.status}`">{{scope.row.statusText}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="gradeText" label="主题类目" width="80" align="center"></el-table-column>
        <el-table-column prop="levelText" label="难度等级" width="80" align="center"></el-table-column>
        <el-table-column prop="title" label="英文标题" min-width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="titleCn" label="中文标题" min-width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="subject" label="主题描述" width="80" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.subject" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display ori-text">{{scope.row.subject}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="images" label="主题图片" width="80" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.images && scope.row.images.length > 0" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display flex">
                <img
                  v-viewer v-for="(img, inx) in scope.row.images" :key="inx"
                  class="headimg" :src="img"
                />
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="goals" label="对话目的" width="80" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.goals" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display ori-text">{{scope.row.goals}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="example" label="对话范本" width="80" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.example" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display ori-text">{{scope.row.example}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="sentence" label="练习的句型" width="90" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.sentence" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display ori-text">{{scope.row.sentence}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="vocabulary" label="练习词汇" width="80" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.vocabulary" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display ori-text">{{scope.row.vocabulary}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="prologueA" label="开场白A" min-width="120" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.prologueA" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display ori-text">{{scope.row.prologueA}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="prologueB" label="开场白B" min-width="120" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.prologueB" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display ori-text">{{scope.row.prologueB}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="prologueExample" label="提问范本" min-width="120" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.prologueExample" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display ori-text">{{scope.row.prologueExample}}</div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column prop="created_at" label="创建时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template v-slot:default="scope">
            <div class="flex-center">
              <el-button link type="primary" @click="toEdit(scope.row)">修改</el-button>
              <el-button  v-if="scope.row.status !== 1" link type="primary" @click="online(scope.row)">上架</el-button>
              <el-button v-else link type="danger" @click="offline(scope.row)">下架</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="layout-bbar">
      <page ref="page" @change="reload" />
    </div>

    <!--新建-->
    <create v-model:value="create.visible" v-if="create.visible" @done="reload" />

    <!--修改-->
    <edit v-model:value="edit.visible" v-if="edit.visible" :item="edit.item" @done="reload" />
  </div>
</template>

<script>

import { STATUS_OFFLINE, STATUS_ONLINE } from '../constants'

import dayjs from 'dayjs'
import { mapState } from 'vuex'
import page from '@/components/page/index'
import filterable from '@/components/table/header.filterable.vue'
import create from './create'
import edit from './edit'

export default {
  components: { page, filterable, create, edit },

  data() {
    return {
      height: '200px',
      list: [],
      order: {
        orderBy: 'id',
        orderType: 'asc'
      },
      params: {
        name: ''
      },

      dicts: null,
      statusOptions: [
        { id: 1, title: '已发布' },
        { id: 2, title: '未发布' },
      ],

      create: {
        visible: false
      },
      loading: false,

      edit: {
        visible: false,
        item: null
      },

      selecteds: []
    }
  },

  computed: {
    ...mapState({
      resized: state => state.runtime.resized,
    })
  },

  async mounted() {
    this.resize()
    this.reload()
  },

  methods: {
    async reload() {
      try {
        this.loading = true
        const params = this.$refs.page.params({
          order_by: this.order.orderBy,
          order_type: this.order.orderType,
          ...this.params
        })
        const { data } = await this.request({
          method: 'GET',
          url: '/api/admin/spoken/topic/list',
          params: params
        })

        this.$refs.page.update(data)
        const selecteds = []
        for (const item of data.data) {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.updated_at = dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')
          item.statusText = item.status === 1 ? '已发布' : '未发布'
          if (this.selecteds.indexOf(item.id) !== -1) {
            selecteds.push(item)
          }
        }
        this.list = data.data
        this.selecteds = selecteds.map(p => p.id)
        this.$nextTick(() => {
          this.$refs.table.clearSelection()
          if (selecteds.length > 0) {
            for (const row of selecteds) this.$refs.table.toggleRowSelection(row, true)
          }
        })

      } finally {
        this.loading = false
      }
    },

    whenSelectChanged(selection) {
      this.selecteds = selection.map(p => p.id)
    },

    toDetail() {

    },

    async online(row) {
      const yes = await this.confirm('确定要上架吗?')
      await this.updateStatus(row, STATUS_ONLINE)
    },

    async offline(row) {
      const yes = await this.confirm('确定要下架吗?')
      await this.updateStatus(row, STATUS_OFFLINE)
    },

    async offlineBatch() {
      if (this.selecteds.length === 0) return this.$message.error('请选择数据')
      const yes = await this.confirm('确定要下架吗?')
      await this.updateStatus(null, STATUS_OFFLINE, this.selecteds)
    },

    async onlineBatch() {
      if (this.selecteds.length === 0) return this.$message.error('请选择数据')
      const yes = await this.confirm('确定要上架吗?')
      await this.updateStatus(null, STATUS_ONLINE, this.selecteds)
    },

    async updateStatus(row, status, selecteds) {
      await this.request({
        method: 'POST',
        url: '/api/admin/spoken/topic/status/update',
        data: {
          id: row ? row.id : null,
          ids: selecteds || null,
          status
        }
      })
      this.reload()
      this.$message.success('操作成功!')
    },

    resize() {
      this.height = this.$refs.container.clientHeight
    },

    onorder({ orderBy, orderType }) {
      this.order.orderBy = orderBy
      this.order.orderType = orderType
      this.reload()
    },

    filter({ id, value }) {
      this.params[id] = value
      this.reload()
    },

    showUpload() {
      this.create.visible = true
    },

    toEdit(row) {
      this.edit.item = row
      this.edit.visible = true
    }
  }
}
</script>

<style>
.headimg{
  margin:6px;
  max-width:60px;
  max-height:60px;
}

.songs-list{
  max-height:300px;
  overflow:auto;
}

.songs-list-item{
  line-height:20px;
}

.textarea-display{
  width:100%;
  max-height:300px;
  overflow:hidden;
}

.ori-text{
  white-space:pre-wrap;
}

.status-1{
  color:#1F8C65;
}

.status-2{
  color:#ccc;
}
</style>
