<template>
  <div>
    <el-dialog title="修改口语老师" v-model="val" width="1000px" :destroy-on-close="true" @closed="closed">
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120">
          <div class="flex">
            <el-form-item label="名字" prop="nickname" class="spkf-item">
              <el-input v-model.trim="form.nickname"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="英文名" prop="nicknameEn" class="spkf-item">
              <el-input v-model.trim="form.nicknameEn"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="标签" prop="tags" class="spkf-item">
              <el-input v-model="form.tags" type="textarea" :row="3"></el-input>
            </el-form-item>

            <el-form-item label="简介" prop="desc" class="spkf-item">
              <el-input v-model.trim="form.desc" type="textarea" :row="3"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="头像" prop="headimg" class="spkf-item">
              <div class="flex">
                <img v-viewer class="spkf-img" :src="form.headimg" v-if="form.headimg" />
                <el-button link type="primary" :loading="form.headimgIng" @click="showImgUpload('headimg')">上传</el-button>
              </div>
            </el-form-item>

            <el-form-item label="主页形象" prop="thumbIndex" class="spkf-item">
              <div class="flex">
                <img v-viewer class="spkf-img" :src="form.thumbIndex" v-if="form.thumbIndex" />
                <el-button link type="primary" :loading="form.thumbIndexIng" @click="showImgUpload('thumbIndex')">上传</el-button>
              </div>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="对话形象" prop="thumb" class="spkf-item">
              <div class="flex">
                <img v-viewer class="spkf-img" :src="form.thumb" v-if="form.thumb" />
                <el-button link type="primary" :loading="form.thumbIng" @click="showImgUpload('thumb')">上传</el-button>
              </div>
            </el-form-item>

            <el-form-item label="人设prompt" prop="prompt" class="spkf-item">
              <el-input v-model.trim="form.prompt" type="textarea" :rows="5"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="开场白话术" prop="prologue" class="spkf-item">
              <el-input v-model.trim="form.prologue"></el-input>
            </el-form-item>

            <el-form-item label="LLM" prop="llm" class="spkf-item">
              <el-select v-model="form.llm">
                <el-option v-for="(item, inx) in llms" :key="inx" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>


          </div>

          <div class="flex">
            <el-form-item label="TTS" prop="ttsVender" class="spkf-item">
              <el-select v-model="form.ttsVender">
                <el-option v-for="(item, inx) in ttses" :key="inx" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="TTS音色" prop="ttsVoice" class="spkf-item">
              <el-input v-model.trim="form.ttsVoice"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="入口文案" prop="btnTip" class="spkf-item">
              <el-input v-model.trim="form.btnTip"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="flex-center">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :loading="saving" @click="submit">确定</el-button>
        </div>
      </template>
    </el-dialog>

    <input type="file" accept=".jpg,.jpeg,.png" style="display:none" ref="input" @change="fileSelected" />
  </div>
</template>

<script>
import mixin from '@/commons/popup.mixin.js'

export default {
  mixins: [mixin],
  props: {
    item: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      form: {
        nickname: '',
        nicknameEn: '',
        tags: '',
        desc: '',
        headimg: '',
        thumbIndex: '',
        thumb: '',
        prompt: '',
        prologue: '',
        llm: '',
        ttsVender: '',
        ttsVoice: '',
        btnTip: '',
      },
      rules: {
        nickname: { required: true, message: '请输入名字' },
        nicknameEn: { required: true, message: '请输入英文字' },
        tags: { required: false, message: '请输入标签' },
        desc: { required: true, message: '请输入介绍' },
        headimg: { required: true, message: '请上传头像' },
        thumbIndex: { required: true, message: '请上传主页形象' },
        thumb: { required: true, message: '请上传对话形象' },
        prompt: { required: true, message: '请输人设prompt' },
        prologue: { required: true, message: '请输入标签' },
        llm: { required: true, message: '请选择大模型' },
        ttsVender: { required: true, message: '请选TTS' },
        ttsVoice: { required: true, message: '请选TTS音色' },
        btnTip: { required: true, message: '请输入入口文案' },
      },
      saving: false,
      fileName: '',

      fileList: [],
      result: {
        state: null
      },

      uploadingFeild: '',
      headimgIng: false,
      thumbIndexIng: false,
      thumbIng: false,

      llms: [
        { value: 'chatgpt3.5', label: 'chatgpt3.5' },
        { value: 'chatgpt4', label: 'chatgpt4' },
        { value: 'sensetime', label: 'sensetime' },
      ],

      ttses: [
        { value: 'sensetime', label: '商量' },
        { value: 'youdao', label: '有道' },
        { value: 'volcengine', label: '火山引擎' },
        { value: 'xunfei', label: '讯飞' }
      ]
    }
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      for (const key in this.form) {
        if (this.item[key] !== undefined) this.form[key] = this.item[key]
      }
    },

    beforeUpload(file) {
      this.form.file = file
      this.fileName = file.name
      return false
    },

    submit() {
      this.$refs.form.validate((yes) => {
        if (yes) this.doSubmit()
      })
    },

    async doSubmit() {
      if (this.saving) return
      try {
        this.saving = true

        const form = {
          id: this.item.id,
          ...this.form
        }

        switch (form.llm) {
          case 'chatgpt3.5':
            form.llmModel = 'gpt-3.5-turbo'
            form.llmVender = 'openai'
            break
          case 'chatgpt4':
            form.llmModel = 'gpt-4'
            form.llmVender = 'openai'
            break
          case 'sensetime':
            form.llmModel = 'SenseChat-32K'
            form.llmVender = 'sensetime'
            break
          default:
        }
        delete form.llm
        form.tags = form.tags.trim().split(/\s/).map(p => p.trim()).filter(p => !!p)

        await this.request({
          method: 'POST',
          url: '/api/admin/spoken/teacher/feild/update',
          data: form
        })
        this.$emit('done')
        this.$message.success('修改成功!')
        this.close()
      } finally {
        this.saving = false
      }
    },

    showImgUpload(feild) {
      this.uploadingFeild = feild
      this.$refs.input.click()
    },

    async fileSelected() {
      const file = this.$refs.input.files[0]
      this.$refs.input.value = ''

      const uploadingFeild = this.uploadingFeild

      try {
        this[`${uploadingFeild}Ing`] = true
        const form = new FormData()
        form.append('file', file)
        const { data } = await this.request({
          method: 'POST',
          url: '/api/admin/spoken/image/upload',
          data: form
        })

        this.$message.success('上传成功')
        this.form[uploadingFeild] = data
      } finally {
        this[`${uploadingFeild}Ing`] = false
      }
    },
  }
}
</script>

<style>
.import-form-upload{
  width:100%;
}

.import-form-upload-box{
  width:400px;
  height:200px;
}

.spkf-img{
  max-width:100px;
  max-height:100px;
}

.spkf-item{
  width:480px;
}
</style>
