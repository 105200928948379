<template>
  <div>
    <el-dialog title="修改主题" v-model="val" width="1000px" :destroy-on-close="true" @closed="closed">
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="140">
          <div class="flex">
            <el-form-item label="主题类目" prop="grade" class="spkf-item">
              <el-input v-model.trim="form.gradeText"></el-input>
            </el-form-item>

            <el-form-item label="难度等级" prop="levelText" class="spkf-item">
              <el-input v-model="form.levelText"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="英文主题" prop="title" class="spkf-item">
              <el-input v-model.trim="form.title"></el-input>
            </el-form-item>

            <el-form-item label="中文主题" prop="titleCn" class="spkf-item">
              <el-input v-model.trim="form.titleCn"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="主题描述" prop="subject" class="spkf-item">
              <el-input v-model.trim="form.subject" type="textarea" :row="3"></el-input>
            </el-form-item>

            <el-form-item label="对话目的" prop="goals" class="spkf-item">
              <el-input v-model.trim="form.goals" type="textarea" :row="3"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="主题图片" prop="images" class="spkf-item-full">
              <div class="flex spkf-item-imgs">
                <div class="spkf-item-img" v-for="(img, inx) in form.images">
                  <img
                    v-viewer class="spkf-img"
                    :src="img"
                  />

                  <div class="spkf-item-img-del" @click="delImg(form.images, inx)">删除</div>
                </div>
                <el-button link type="primary" :loading="imgIng" @click="showImgUpload('images')">上传</el-button>
              </div>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="对话范本" prop="example" class="spkf-item">
              <el-input v-model.trim="form.example" type="textarea" :row="3"></el-input>
            </el-form-item>

            <el-form-item label="练习的句型" prop="sentence" class="spkf-item">
              <el-input v-model.trim="form.sentence" type="textarea" :row="3"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="开场白A(首次)" prop="prologueA" class="spkf-item">
              <el-input v-model.trim="form.prologueA" type="textarea" :row="5"></el-input>
            </el-form-item>

            <el-form-item label="开场白B(非首次)" prop="prologueB" class="spkf-item">
              <el-input v-model.trim="form.prologueB" type="textarea" :row="5"></el-input>
            </el-form-item>
          </div>

          <div class="flex">
            <el-form-item label="练习词汇" prop="vocabulary" class="spkf-item">
              <el-input v-model.trim="form.vocabulary" type="textarea" :row="3"></el-input>
            </el-form-item>

            <el-form-item label="提问范本" prop="prologue" class="spkf-item">
              <el-input v-model.trim="form.prologueExample" type="textarea" :row="5"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="flex-center">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :loading="saving" @click="submit">确定</el-button>
        </div>
      </template>
    </el-dialog>

    <input type="file" accept=".jpg,.jpeg,.png" style="display:none" ref="input" @change="fileSelected" multiple />
  </div>
</template>

<script>
import mixin from '@/commons/popup.mixin.js'

export default {
  mixins: [mixin],
  props: {
    item: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      form: {
        gradeText: '',
        levelText: '',
        title: '',
        titleCn: '',
        subject: '',
        goals: '',
        example: '',
        sentence: '',
        vocabulary: '',
        // prologue: '',
        prologueA: '',
        prologueB: '',
        prologueExample: '',
        images: []
      },
      rules: {
        gradeText: { required: true, message: '请输入主题类目' },
        levelText: { required: false, message: '请输入难度等级' },
        title: { required: true, message: '请输入英文主题' },
        titleCn: { required: true, message: '请输入中文主题' },
        subject: { required: false, message: '请输入主题描述' },
        goals: { required: false, message: '请输入对话目的' },
        example: { required: false, message: '请输入对话范本' },
        sentence: { required: false, message: '请输入练习的句型' },
        vocabulary: { required: false, message: '请输入练习词汇' },
        // prologue: { required: true, message: '请输入开场白' },
        prologueA: { required: false, message: '请输入开场白A' },
        prologueB: { required: false, message: '请输入开场白B' },
        prologueExample: { required: false, message: '请输入提问范本' },
      },
      saving: false,
      fileName: '',

      fileList: [],
      result: {
        state: null
      },

      uploadingFeild: '',
      imagesIng: false,

      grades: []
    }
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.reloadDicts()
      for (const key in this.form) {
        if (this.item[key] !== undefined) {
          if (key === 'images') {
            this.form[key] = [...(this.item[key] || [])]
          } else {
            this.form[key] = this.item[key]
          }
        }
      }
    },

    async reloadDicts() {
      const { data } = await this.request({
        method: 'POST',
        url: '/api/admin/sys/dict/options',
        data: {
          keys: ['grade']
        }
      })

      this.grades = data.grade.map(p => {
        return {
          value: Number(p.id),
          label: p.title
        }
      })
    },

    showImgUpload(feild) {
      this.uploadingFeild = feild
      this.$refs.input.click()
    },

    async fileSelected() {
      const files = [...this.$refs.input.files]
      this.$refs.input.value = ''

      const uploadingFeild = this.uploadingFeild

      try {
        this[`${uploadingFeild}Ing`] = true
        const tasks = []
        for (let i=0; i<files.length; i++) {
          tasks.push(this.upload(files[i]))
        }
        const imgs = await Promise.all(tasks)
        this.$message.success('上传成功')
        this.form[uploadingFeild] = [...this.form[uploadingFeild], ...imgs]
      } finally {
        this[`${uploadingFeild}Ing`] = false
      }
    },

    async upload(file) {
      const form = new FormData()
      form.append('file', file)
      const { data } = await this.request({
        method: 'POST',
        url: '/api/admin/spoken/image/upload',
        data: form
      })
      return data
    },

    beforeUpload(file) {
      this.form.file = file
      this.fileName = file.name
      return false
    },

    delImg(imgs, inx) {
      imgs.splice(inx, 1)
    },

    submit() {
      this.$refs.form.validate((yes) => {
        if (yes) this.doSubmit()
      })
    },

    async doSubmit() {
      if (this.saving) return
      try {
        this.saving = true

        const form = {
          id: this.item.id,
          ...this.form
        }

        await this.request({
          method: 'POST',
          url: '/api/admin/spoken/topic/feild/update',
          data: form
        })
        this.$emit('done')
        this.$message.success('修改成功!')
        this.close()
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style>
.import-form-upload{
  width:100%;
}

.import-form-upload-box{
  width:400px;
  height:200px;
}

.spkf-img{
  margin:6px;
  max-width:100px;
  max-height:100px;
}

.spkf-item{
  width:480px;
}

.spkf-item-full{
  width:960px;
}

.spkf-item-imgs{
  flex-wrap:wrap;
}

.spkf-item-img{
  position:relative;
}

.spkf-item-img-del{
  padding:3px 6px;
  font-size:12px;
  background:rgba(0,0,0,0.3);
  color:#fff;
  position:absolute;
  top:2px;
  right:2px;
  cursor:pointer;
}
</style>
