<template>
  <div>
    <el-dialog title="新建口语老师" v-model="val" width="460px" :destroy-on-close="true" @closed="closed">
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="0" class="import-form">
          <el-form-item prop="file">
            <div class="flex-center import-form-upload">
              <el-upload
                accept=".xlsx"
                drag
                :file-list="fileList"
                :before-upload="beforeUpload"
              >
                <div class="import-form-upload-box flex-column-center">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__text">{{fileName}}</div>
                </div>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="flex-center">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :loading="saving" @click="submit">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/commons/popup.mixin.js'

export default {
  mixins: [mixin],
  data() {
    return {
      form: {
        file: null
      },
      rules: {
        file: { required: true, message: '请选择数据文件' }
      },
      saving: false,
      fileName: '',

      fileList: [],
      result: {
        state: null
      }
    }
  },

  created() {
  },

  methods: {
    beforeUpload(file) {
      this.form.file = file
      this.fileName = file.name
      return false
    },

    submit() {
      this.$refs.form.validate((yes) => {
        if (yes) this.doSubmit()
      })
    },

    async doSubmit() {
      if (this.saving) return
      try {
        this.saving = true
        const form = new FormData()
        form.append('file', this.form.file)
        await this.request({
          method: 'POST',
          url: '/api/admin/spoken/topic/import',
          data: form
        })
        this.$emit('done')
        this.$message.success('导入成功!')
        this.close()
      } finally {
        this.saving = false
      }
    },
  }
}
</script>

<style>
.import-form-upload{
  width:100%;
}

.import-form-upload-box{
  width:400px;
  height:200px;
}
</style>
