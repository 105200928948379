<template>
  <div>
    <el-dialog title="主题关联" v-model="val" width="800px" :destroy-on-close="true" @closed="closed">
      <div>
        <el-table
          :data="list"
          height="300px"
          border style="width: 100%"
          ref="table"
          v-loading="loading"
          row-key="id"
          @selection-change="whenSelectChanged"
        >
          <el-table-column
            label="关联"
            type="selection" width="60" align="center" :reserve-selection="true" fixed="left"
          />
          <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
          <el-table-column prop="status" label="状态" width="70" align="center">
            <template v-slot:default="scope">
              <div :class="`status-${scope.row.status}`">{{scope.row.statusText}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="gradeText" label="主题类目" width="80" align="center"></el-table-column>
          <el-table-column prop="levelText" label="难度等级" width="120" align="center"></el-table-column>
          <el-table-column prop="title" label="英文标题" min-width="100" align="center"></el-table-column>
          <el-table-column prop="titleCn" label="中文标题" min-width="100" align="center"></el-table-column>
        </el-table>
      </div>
      <template v-slot:footer>
        <div class="flex-center">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :loading="saving" @click="submit">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/commons/popup.mixin.js'
import dayjs from 'dayjs'

export default {
  mixins: [mixin],
  props: {
    item: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      saving: false,

      selecteds: []
    }
  },

  created() {
    this.reload()
  },

  methods: {
    async reload() {
      try {
        this.loading = true
        const { data } = await this.request({
          method: 'GET',
          url: '/api/admin/spoken/topic/list',
          params: {
            start: 0,
            size: 999
          }
        })
        const checkeds = this.item.topics.map(p => p.topicId)
        const selecteds = []
        for (const item of data.data) {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.updated_at = dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')
          item.statusText = item.status === 1 ? '已发布' : '未发布'
          if (checkeds.indexOf(item.id) !== -1) selecteds.push(item)
        }
        this.selecteds = selecteds.map(p => p.id)
        this.$nextTick(() => {
          this.$refs.table.clearSelection()
          if (selecteds.length > 0) {
            for (const row of selecteds) this.$refs.table.toggleRowSelection(row, true)
          }
        })
        this.list = data.data
      } finally {
        this.loading = false
      }
    },

    whenSelectChanged(selection) {
      this.selecteds = selection.map(p => p.id)
    },

    changed({ $index }, checked) {
      const topic = this.list[$index]
      topic.checked = checked
    },

    async submit() {
      if (this.saving) return
      try {
        this.saving = true
        const checkeds = this.list.filter(p => p.checked).map(p => p.id)
        await this.request({
          method: 'POST',
          url: '/api/admin/spoken/teacher/topic/relative',
          data: {
            ip_id: this.item.ipId,
            topic_ids: this.selecteds
          }
        })
        this.$emit('done')
        this.close()
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style>

</style>
