<template>
  <div class="container layout-container">
    <div class="layout-bar">
      <el-button type="primary" @click="showUpload">新增</el-button>
      <el-button type="danger" @click="offlineBatch">下架</el-button>
      <el-button type="primary" @click="onlineBatch">上架</el-button>
      <el-button type="primary" @click="reload">刷新</el-button>
    </div>
    <div class="layout-content" ref="container">
      <el-table
        :data="list"
        :height="height"
        row-key="id"
        ref="table"
        border style="width: 100%"
        v-loading="loading"
        @selection-change="whenSelectChanged"
      >
        <el-table-column
          type="selection" width="40" align="center" :reserve-selection="true" fixed="left"
        />
        <el-table-column prop="id" label="id" width="80" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" inputType="options" :options="statusOptions" :column="scope.column" @filter="filter" />
          </template>
          <template v-slot:default="scope">
            <div :class="`status-${scope.row.status}`">{{scope.row.statusText}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="名字" min-width="80" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="nicknameEn" label="英文名" min-width="80" align="center"></el-table-column>
        <el-table-column class-name="ori-text" prop="tags" label="标签" width="80" align="center"></el-table-column>
        <el-table-column class-name="ori-text" prop="desc" label="简介" width="140" align="center"></el-table-column>
        <el-table-column prop="thumbIndex" label="主页形象" width="80" align="center">
          <template v-slot:default="scope">
            <img v-viewer class="headimg" :src="scope.row.thumbIndex" v-if="scope.row.thumbIndex" />
          </template>
        </el-table-column>
        <el-table-column prop="thumb" label="对话形象" width="90" align="center">
          <template v-slot:default="scope">
            <img v-viewer class="headimg" :src="scope.row.thumb" v-if="scope.row.thumb" />
          </template>
        </el-table-column>
        <el-table-column prop="prologue" label="开场白" width="140" align="center"></el-table-column>
        <el-table-column prop="cover" label="prompt" width="80" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.prompt" class="item" width="300px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <div class="textarea-display ori-text">{{scope.row.prompt}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="llm" label="LLM" min-width="100" align="center"></el-table-column>
        <el-table-column prop="tts" label="TTS" min-width="110" align="center"></el-table-column>
        <el-table-column prop="topics" label="关联主题ID" width="100" align="center">
          <template v-slot:default="scope">
            <div class="flex">
              <el-popover trigger="click" class="item" width="300px">
                <template #reference>
                  <el-button link type="primary">查看</el-button>
                </template>

                <el-table :data="scope.row.topics" border style="width:100%;">
                  <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                  <el-table-column prop="title" label="标题" width="194" align="center"></el-table-column>
                </el-table>
              </el-popover>

              <el-button link type="primary" @click="showTopicRelate(scope.row)">编辑</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" width="140" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="140" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template v-slot:default="scope">
            <div class="flex-center">
              <el-button link type="primary" @click="toEdit(scope.row)">修改</el-button>
              <el-button  v-if="scope.row.status !== 1" link type="primary" @click="online(scope.row)">上架</el-button>
              <el-button v-else link type="danger" @click="offline(scope.row)">下架</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="layout-bbar">
      <page ref="page" @change="reload" />
    </div>

    <!--新建-->
    <create v-model:value="create.visible" v-if="create.visible" @done="reload" />

    <!--修改-->
    <edit v-model:value="edit.visible" v-if="edit.visible" :item="edit.item" @done="reload" />

    <!--主题关联-->
    <topics v-model:value="topics.visible" v-if="topics.visible" :item="topics.item" @done="reload" />
  </div>
</template>

<script>

import { STATUS_OFFLINE, STATUS_ONLINE } from '../constants'

import dayjs from 'dayjs'
import { mapState } from 'vuex'
import page from '@/components/page/index'
import filterable from '@/components/table/header.filterable.vue'
import create from './create'
import edit from './edit'
import topics from './topics'

export default {
  components: { page, filterable, create, edit, topics },

  data() {
    return {
      height: '200px',
      list: [],
      order: {
        orderBy: 'id',
        orderType: 'asc'
      },
      params: {
        name: ''
      },

      dicts: null,
      statusOptions: [
        { id: 1, title: '已发布' },
        { id: 2, title: '未发布' },
      ],

      create: {
        visible: false
      },
      loading: false,

      edit: {
        visible: false,
        item: null
      },

      topics: {
        visible: false,
        item: null
      },

      selecteds: []
    }
  },

  computed: {
    ...mapState({
      resized: state => state.runtime.resized,
    })
  },

  async mounted() {
    this.resize()
    await this.reloadDicts()
    this.reload()
  },

  methods: {
    async reloadDicts() {
      const { data } = await this.request({
        method: 'POST',
        url: '/api/admin/sys/dict/options',
        data: {
          keys: ['grade']
        }
      })

      this.dicts = {
        ...data
      }
    },

    async reload() {
      try {
        this.loading = true
        const params = this.$refs.page.params({
          order_by: this.order.orderBy,
          order_type: this.order.orderType,
          ...this.params
        })
        const { data } = await this.request({
          method: 'GET',
          url: '/api/admin/spoken/teacher/list',
          params: params
        })

        this.$refs.page.update(data)
        const selecteds = []
        for (const item of data.data) {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.updated_at = dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')
          item.tags = item.tags.map(p => p.name).join('\n')
          item.statusText = item.status === 1 ? '已发布' : '未发布'
          item.llm = ''
          if (item.llmModel === 'gpt-3.5-turbo') {
            item.llm = 'chatgpt3.5'
          } else if (item.llmModel === 'gpt-4') {
            item.llm = 'chatgpt4'
          } else if (item.llmModel === 'SenseChat-32K') {
            item.llm = 'sensetime'
          } else {
            item.llm = item.llmModel
          }
          item.tts = ''
          switch (item.ttsVender) {
            case 'sensetime':
              item.tts = `商量\n${item.ttsVoice}`
              break
            case 'youdao':
              item.tts = `有道\n${item.ttsVoice}`
              break
            case 'volcengine':
              item.tts = `火山引擎\n${item.ttsVoice}`
              break
            case 'xunfei':
              item.tts = `讯飞\n${item.ttsVoice}`
              break
            default:

          }

          if (this.selecteds.indexOf(item.id) !== -1) {
            selecteds.push(item)
          }
        }
        this.list = data.data
        this.selecteds = selecteds.map(p => p.id)
        this.$nextTick(() => {
          this.$refs.table.clearSelection()
          if (selecteds.length > 0) {
            for (const row of selecteds) this.$refs.table.toggleRowSelection(row, true)
          }
        })
      } finally {
        this.loading = false
      }
    },

    whenSelectChanged(selection) {
      this.selecteds = selection.map(p => p.id)
    },

    toEdit(row) {
      this.edit.item = row
      this.edit.visible = true
    },

    async online(row) {
      const yes = await this.confirm('确定要上架吗?')
      await this.updateStatus(row, STATUS_ONLINE)
    },

    async offline(row) {
      const yes = await this.confirm('确定要下架吗?')
      await this.updateStatus(row, STATUS_OFFLINE)
    },

    async offlineBatch() {
      if (this.selecteds.length === 0) return this.$message.error('请选择数据')
      const yes = await this.confirm('确定要下架吗?')
      await this.updateStatus(null, STATUS_OFFLINE, this.selecteds)
    },

    async onlineBatch() {
      if (this.selecteds.length === 0) return this.$message.error('请选择数据')
      const yes = await this.confirm('确定要上架吗?')
      await this.updateStatus(null, STATUS_ONLINE, this.selecteds)
    },

    async updateStatus(row, status, selecteds) {
      await this.request({
        method: 'POST',
        url: '/api/admin/spoken/teacher/status/update',
        data: {
          id: row ? row.id : null,
          ids: selecteds || null,
          status
        }
      })
      this.reload()
      this.$message.success('操作成功!')
    },

    resize() {
      this.height = this.$refs.container.clientHeight
    },

    onorder({ orderBy, orderType }) {
      this.order.orderBy = orderBy
      this.order.orderType = orderType
      this.reload()
    },

    filter({ id, value }) {
      this.params[id] = value
      this.reload()
    },

    showUpload() {
      this.create.visible = true
    },

    showTopicRelate(row) {
      this.topics.item = row
      this.topics.visible = true
    }
  }
}
</script>

<style>
.headimg{
  max-width:60px;
  max-height:60px;
}

.songs-list{
  max-height:300px;
  overflow:auto;
}

.songs-list-item{
  line-height:20px;
}

.textarea-display{
  width:100%;
  height:300px;
  overflow:hidden;
}

.ori-text, .ori-text .cell{
  white-space:pre-wrap;
}

.status-1{
  color:#1F8C65;
}

.status-2{
  color:#ccc;
}
</style>
